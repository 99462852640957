// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donate-thank-you-index-js": () => import("./../../../src/pages/donate/thank-you/index.js" /* webpackChunkName: "component---src-pages-donate-thank-you-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-support-us-index-js": () => import("./../../../src/pages/support-us/index.js" /* webpackChunkName: "component---src-pages-support-us-index-js" */),
  "component---src-pages-teach-index-js": () => import("./../../../src/pages/teach/index.js" /* webpackChunkName: "component---src-pages-teach-index-js" */),
  "component---src-pages-volunteer-index-js": () => import("./../../../src/pages/volunteer/index.js" /* webpackChunkName: "component---src-pages-volunteer-index-js" */)
}

