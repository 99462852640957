require('intersection-observer')
require('url-search-params-polyfill')
require('whatwg-fetch')

const reachRouter = require('@reach/router')

exports.onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  // eslint-disable-next-line no-underscore-dangle
  reachRouter.globalHistory._onTransitionComplete()
}
